<template>
  <a-drawer
    title="派单"
    :maskClosable="false"
    width="750"
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="taskAddVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form :form="form">
      <a-form-item label="项目" v-bind="formItemLayout">
        <project-input-select
          @change="onChange"
          v-decorator="[
            'projectId',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
        >
        </project-input-select>
      </a-form-item>
      <a-form-item label="工单类型" v-bind="formItemLayout">
        <a-select
          v-decorator="[
            'taskType',
            { rules: [{ required: true, message: '请选择工单类型' }] },
          ]"
        >
          <a-select-option
            v-for="(val, key) in taskType"
            :key="key"
            :value="val.keyy"
            >{{val.valuee }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="问题描述" v-bind="formItemLayout">
        <a-input v-decorator="['description']" />
      </a-form-item>
      <a-form-item label="预计时段" v-bind="formItemLayout">
        <a-range-picker
          v-decorator="[
            'expectedTime',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="客户地址" v-bind="formItemLayout">
        <v-distpicker
          @selected="onSelected"
          :province="task.province"
          :city="task.city"
          :area="task.county"
        ></v-distpicker>
        <a-input v-model="task.address" />
      </a-form-item>
      <a-form-item label="客户名称" v-bind="formItemLayout">
        <a-input v-decorator="['customer']" />
      </a-form-item>
      <a-form-item label="客户联系人" v-bind="formItemLayout">
        <a-input v-decorator="['contact']" />
      </a-form-item>
      <a-form-item label="客户电话" v-bind="formItemLayout">
        <a-input v-decorator="['phone']" />
      </a-form-item>
      <a-form-item label="服务工程师" v-bind="formItemLayout">
        <a-row :gutter="8">
          <a-col :span="16">
            <engineers-select mode="multiple" v-model="engineers">
            </engineers-select>
          </a-col>
          <a-col :span="8">
            <a-icon type="user-add" @click="addAssign()" />
          </a-col>
        </a-row>
        <a-row
          :gutter="8"
          v-for="(item, index) of task.taskAssigns"
          :key="index"
        >
          <a-col :span="12">
            {{ item.engineer.username }}
          </a-col>
          <a-col :span="12">
            <a-icon
              type="delete"
              theme="twoTone"
              two-tone-color="#eb2f96"
              @click="removeAssign(item)"
            />
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <div class="drawer-bootom-button">
			<a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";
import VDistpicker from "v-distpicker";
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
import ProjectInputSelect from "../../business-common/ProjectInputSelect";
import EngineersSelect from "../../business-common/EngineersSelect";

export default {
  name: "taskAdd",
  components: { EngineersSelect, ProjectInputSelect, VDistpicker },
  props: {
    taskAddVisiable: {
      default: false,
    },
    taskType: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
      value: [],
      engineers: [],
      task: {
        taskAssigns: [],
        province: "",
        city: "",
        area: "",
        address: "",
      },
    };
  },
  created() {

  },
  methods: {
    reset() {
      this.loading = false;
      this.task = {
        taskAssigns: [],
        province: "",
        city: "",
        area: "",
        address: "",
      };
      this.form.resetFields();
    },
    onClose() {
      this.reset();
      this.$emit("close");
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.setTaskFields();
          if (
            this.task.province == "" || this.task.city == "" 
          ) {
            this.$notification.warning({
              message: "警告提示",
              description: "省市为必填",
            });
            return;
          }
          this.loading = true;
          this.$post("task", {
            ...this.task,
          })
            .then(() => {
              this.reset();
              this.$emit("success");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    setTaskFields() {
      let values = this.form.getFieldsValue([
        "projectId",
        "taskType",
        "expectedTime",
        "primeEngineer",
        "accessaryEngineers",
        "customer",
        "contact",
        "phone",
        "description",
      ]);
      if (typeof values !== "undefined") {
        Object.keys(values).forEach((_key) => {
          this.task[_key] = values[_key];
        });
        this.task.beginDate = moment(this.task.expectedTime[0]).valueOf();
        this.task.endDate = moment(this.task.expectedTime[1]).valueOf();
      }
    },
    removeAssign(item) {
      this.task.taskAssigns.splice(
        this.task.taskAssigns.findIndex((v) => v.assignId === item.assignId),
        1
      );
    },
    addAssign() {
      for (const item of this.engineers) {
        if (
          this.task.taskAssigns.find((v) => v.userId == item.key) == undefined
        ) {
          this.task.taskAssigns.push({
            userId: item.key,
            engineer: { username: item.label },
          });
        }
      }
      this.engineers = [];
    },
    onSelected(data) {
      this.task.province = data.province.value;
      this.task.city = data.city.value;
      this.task.area = data.area.value;
    },
    onChange(value) {
      if (value == undefined) {
        return
      }
      this.$get(`task/list-by-project?projectId=${value}`).then((r) => {
        const taskList = r.data.data;
        if (taskList.length > 0) {
          if (taskList[0].province!=undefined) {
            this.task.province = taskList[0].province
          }
          if (taskList[0].city!=undefined) {
            this.task.city = taskList[0].city
          }
          if (taskList[0].area!=undefined) {
            this.task.area = taskList[0].area
          }
          if (taskList[0].address!=undefined) {
            this.task.address = taskList[0].address
          }
          let obj = {}
          if (taskList[0].customer!=undefined) {
            obj.customer = taskList[0].customer
          }
          if (taskList[0].contact!=undefined) {
            obj.contact = taskList[0].contact
          }
          if (taskList[0].phone!=undefined) {
            obj.phone = taskList[0].phone
          }
          this.form.setFieldsValue(obj);
        }
      });
    }
  },
};
</script>
