<template>
  <a-modal
    v-model="show"
    title="工单遗留问题"
    :footer="null"
    :width="1000"
    @cancel="handleCancleClick"
  >
    <a-row :gutter="8">
      <a-col :span="12"> 工单号： </a-col>
      <a-col :span="12">
        {{ taskData.taskNo }}
      </a-col>
    </a-row>
    <a-row :gutter="8">
      <a-col :span="12"> 遗留问题： </a-col>
      <a-col :span="12">
        {{ taskData.leftoverProblem }}
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
export default {
  name: "taskLeftover",
  props: {
    leftoverVisiable: {
      require: true,
      default: false,
    },
    taskData: {
      require: true,
    },
  },
  computed: {
    show: {
      get: function () {
        return this.leftoverVisiable;
      },
      set: function () {},
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCancleClick() {
      this.$emit("close");
    },
  },
};
</script>
