<template>
  <a-drawer
    title="修改工单"
    :maskClosable="false"
    width="750"
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="taskEditVisiable"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <a-form :form="form">
      <a-form-item label="工单号" v-bind="formItemLayout">
        <a-input v-decorator="[
        'taskNo',
        { rules: [{ required: true, message: '不能为空' }, {validator: this.validateTaskNo}],validateTrigger: ['blur'] },
        ]" />
      </a-form-item>
      <a-form-item label="派单时间" v-bind="formItemLayout">
        <a-date-picker showTime format='YYYY-MM-DD HH:mm:ss' v-decorator="[ 'createTime', {rules: [{ required: true, message: '不能为空' }]}]"/>
      </a-form-item>      
      <a-form-item label="项目" v-bind="formItemLayout">
        <project-input-select
          v-decorator="[
            'projectId',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
          :defaultProjectList="defaultProjectList"
        >
        </project-input-select>
      </a-form-item>
      <a-form-item label="工单类型" v-bind="formItemLayout">
        <a-select
          v-decorator="[
            'taskType',
            { rules: [{ required: true, message: '请选择工单类型' }] },
          ]"
        >
          <a-select-option
            v-for="(val, key) in taskType"
            :key="key"
            :value="val.keyy"
            >{{val.valuee }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="问题描述" v-bind="formItemLayout">
        <a-input v-decorator="['description']" />
      </a-form-item>
      <a-form-item label="预计时段" v-bind="formItemLayout">
        <a-range-picker
          v-decorator="[
            'expectedTime',
            { rules: [{ required: true, message: '不能为空' }] },
          ]"
        />
      </a-form-item>
      <a-form-item label="状态" v-bind="formItemLayout">
        <a-radio-group
          v-decorator="[
            'status',
            { rules: [{ required: true, message: '请选择状态' }] },
          ]"
        >
          <a-radio :key="key" :value="val.keyy" v-for="(val, key) in status">{{
            val.valuee
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="客户地址" v-bind="formItemLayout">
        <v-distpicker
          @selected="onSelected"
          :province="task.province"
          :city="task.city"
          :area="task.area"
        ></v-distpicker>
        <a-input v-model="task.address" />
      </a-form-item>
      <a-form-item label="客户名称" v-bind="formItemLayout">
        <a-input v-decorator="['customer']" />
      </a-form-item>
      <a-form-item label="客户联系人" v-bind="formItemLayout">
        <a-input v-decorator="['contact']" />
      </a-form-item>
      <a-form-item label="客户电话" v-bind="formItemLayout">
        <a-input v-decorator="['phone']" />
      </a-form-item>
      <a-form-item label="服务工程师" v-bind="formItemLayout">
        <a-row :gutter="8">
          <a-col :span="16">
            <engineers-select mode="multiple" v-model="engineers">
            </engineers-select>
          </a-col>
          <a-col :span="8">
            <a-icon type="user-add" @click="addAssign()" />
          </a-col>
        </a-row>
        <a-row
          :gutter="8"
          v-for="(item, index) of task.taskAssigns"
          :key="index"
        >
          <a-col :span="12">
            {{ item.engineer.username }}
          </a-col>
          <a-col :span="12">
            <a-icon
              type="delete"
              theme="twoTone"
              two-tone-color="#eb2f96"
              @click="removeAssign(item)"
            />
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <div class="drawer-bootom-button">
			<a-button @click="onClose" style="margin-right: 0.8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading"
        >提交</a-button
      >
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
import ProjectInputSelect from "../../business-common/ProjectInputSelect";
import EngineersSelect from "../../business-common/EngineersSelect";

export default {
  name: "taskEdit",
  components: { EngineersSelect, ProjectInputSelect },
  props: {
    taskEditVisiable: {
      default: false,
    },
    taskType: {
      type: Array,
      required: true,
      default: () => [],
    },
    status: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
      engineers: [],
      defaultProjectList: [],
      task: {},
    };
  },
  created() {
  },
  methods: {
    reset() {
      this.loading = false;
      this.form.resetFields();
    },
    onClose() {
      this.reset();
      this.$emit("close");
    },
    setFormValues({ ...task }) {
      let fields = [
        "taskNo",
        "projectId",
        "taskType",
        "beginDate",
        "endDate",
        "customer",
        "contact",
        "phone",
        "description",
        "status",
        "createTime",
      ];
      console.log(task, 'tasl')
      Object.keys(task).forEach((key) => {
        if (fields.indexOf(key) !== -1) {
          this.form.getFieldDecorator(key);
          let obj = {};
          obj[key] = task[key];
          this.form.setFieldsValue(obj);
        }
      });
      this.form.getFieldDecorator("expectedTime");
      this.form.setFieldsValue({
        expectedTime: [moment(task.beginDate), moment(task.endDate)],
      });
      this.form.setFieldsValue({createTime: moment(task.createTime)});
      this.task = task;
      this.defaultProjectList = [{projectId: task.projectId, name: task.project.name, contractNo: task.project.contractNo}]
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let task = this.form.getFieldsValue();
          task.beginDate = moment(task.expectedTime[0]).valueOf();
          task.endDate = moment(task.expectedTime[1]).valueOf();
          task.taskId = this.task.taskId;
          task.taskAssigns = this.task.taskAssigns;
          task.province = this.task.province;
          task.city = this.task.city;
          task.area = this.task.area;
          task.address = this.task.address;
          task.createTime = moment(task.createTime).valueOf();
          if (
            this.task.province == "" || this.task.city == "" 
          ) {
            this.$notification.warning({
              message: "警告提示",
              description: "省市为必填",
            });
            return;
          }
          this.$put("task", {
            ...task,
          })
            .then(() => {
              this.reset();
              this.$emit("success");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    removeAssign(item) {
      this.task.taskAssigns.splice(
        this.task.taskAssigns.findIndex((v) => v.assignId === item.assignId),
        1
      );
    },
    addAssign() {
      for (const item of this.engineers) {
        if (
          this.task.taskAssigns.find((v) => v.userId == item.key) == undefined
        ) {
          this.task.taskAssigns.push({
            taskId: this.task.taskId,
            userId: item.key,
            engineer: { username: item.label },
          });
        }
      }
    },
    onSelected(data) {
      this.task.province = data.province.value;
      this.task.city = data.city.value;
      this.task.area = data.area.value;
    },
    validateTaskNo(rule, value, callback) {
      console.log(this.task.taskId, 'taskId')
      this.$post("task/duplicate-check", {taskId: this.task.taskId, taskNo: value})
        .then((res) => {
          console.log(res,'res')
          if (res.data.code == '200') {
            callback()
          }
          else {
            callback(new Error(res.data.message))
          }
        })
        .catch(() => {
          callback(new Error('校验异常'))
        });
    },
  },
};
</script>
