<template>
  <a-modal
    v-model="show"
    title="客服反馈回执单"
    :footer="null"
    :width="1000"
    @cancel="handleCancleClick"
  >
    <a-row>
      <a-col :span="6"> 工单号： </a-col>
      <a-col :span="18">
        {{ taskData.taskNo }}
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6"> 客户回执： </a-col>
      <a-col :span="18">
        <a-card
          hoverable
          style="width: 240px"
          v-for="(item, index) of taskData.taskReceipts"
          :key="index"
        >
          <img slot="cover" alt="example" :src="$store.state.baseinfo.filedomain + item.receiptPic" />
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
export default {
  name: "taskReceipt",
  props: {
    receiptVisiable: {
      require: true,
      default: false,
    },
    taskData: {
      require: true,
    },
  },
  computed: {
    show: {
      get: function () {
        return this.receiptVisiable;
      },
      set: function () {},
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCancleClick() {
      this.$emit("close");
    },
  },
};
</script>
