<template>
  <a-select
    :mode="mode"
    show-search
    labelInValue
    placeholder="输入员工关键字"
    option-filter-prop="children"
    style="width: 100%"
    :value="userId"
    @change="handleChange"
    :filter-option="filterOption"
  >
    <a-select-option v-for="u in userData" :key="u.userId" :value="u.userId">{{u.username}}</a-select-option>
  </a-select>
</template>
<script>
export default {
  name: 'EngineersSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Number | Array
      // 这个参数是v-decorator给子组件传值用的
      // 这里不要给默认值， 在form下使用会爆警告 Warning: SquareUpload `default value` can not collect,  please use `option.initialValue` to set default value.
    },
    mode: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      userId: undefined,
      userData: []
    }
  },
  mounted () {
    this.$get('user/engineers').then((r) => {
      this.userData = r.data.data
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange (value) {
      this.userId = value
    }
  },
  watch: {
    // 监听数据变化，及时提交给父组件
    userId: {
      deep: true,
      immediate: true,
      handler: function (newV, oldV) {
        // 向父组件更新
        this.$emit('change', newV)
      }
    },
    // 监听父组件传递过来的数据
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.userId = newV
      }
    }
  }
};
</script>
