<template>
  <a-select
    show-search
    placeholder="输入项目关键字"
    option-filter-prop="children"
    style="width: 100%"
    :value="projectId"
    @search="fetchProjectName"
    @change="onProjectNameChange"
    :show-arrow="false"
  >
    <a-select-option
      v-for="p in projectNameList"
      :key="p.projectId"
      :value="p.projectId"
      >{{ p.contractNo }}-{{ p.name }}</a-select-option
    >
  </a-select>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "ProjectInputSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      String: Number,
      // 这个参数是v-decorator给子组件传值用的
      // 这里不要给默认值， 在form下使用会爆警告 Warning: SquareUpload `default value` can not collect,  please use `option.initialValue` to set default value.
    },
    defaultProjectList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    this.lastFetchId = 0; // 只取最后一次结果
    this.fetchProjectName = debounce(this.doSearchProjectNameList, 800);
    return {
      fetching: false, // 查询时的loading
      projectNameList: [], // 列表
      projectId: undefined,
      projectData: [],
    };
  },
  mounted() {
    this.projectNameList = this.defaultProjectList
  },
  methods: {
    onProjectNameChange(value) {
      this.projectId = value;
    },
    doSearchProjectNameList(value) {
      this.projectNameList = [];
      this.fetching = true;
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      if (value == "") {
        return;
      }
      this.$get("project/list?name=" + value)
        .then((r) => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          this.fetching = false;
          this.projectNameList = r.data.data || [];
        })
        .catch(() => {
          this.projectNameList = [];
          this.fetching = false;
        });
    },
  },
  watch: {
    // 监听数据变化，及时提交给父组件
    projectId: {
      deep: true,
      immediate: true,
      handler: function (newV, oldV) {
        // 向父组件更新
        this.$emit("change", newV);
      },
    },
    // 监听父组件传递过来的数据
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.projectId = newV;
      },
    },
    // 监听父组件传递过来的数据
    defaultProjectList: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.projectNameList = newV;
      },
    },
  },
};
</script>
